import React from "react";
import './LoaderChecked.css';

const LoaderChecked = () => {
	return (
		<div className="loadingio-spinner-dual-ball-ehnduvxrdtd">
			<div className="ldio-f8yo9nicvx7">
				<div /> <div /> <div />
			</div>
		</div>
	)
}

export default LoaderChecked;

