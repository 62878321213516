import React from "react";
import "./LoaderResetPass.css";

const LoaderResetPass = () => {
	return (
		<div className="loadingio-spinner-reload-tza0xslpnz">
			<div className="ldio-orn7fdbitof">
				<div>
					<div /> <div /> <div />
				</div>
			</div>
		</div>
	)
}

export default LoaderResetPass;